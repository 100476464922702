import React from 'react';
import styled from 'styled-components';

const HeroContainer = styled.div`
  height: 420px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #00a5c8; /* For browsers that do not support gradients */
  background: linear-gradient(103.61deg, #00a5c8 55.72%, #9fe6f4 114.08%);
  box-shadow: 0px 31px 43px -14px rgba(161, 161, 161, 0.46);
  justify-content: center;
  align-items: center;
`;

const HeroTitle = styled.h1`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -1px;
  color: #ffffff;
  margin-top: 131px;
  margin-bottom: 0px;

  @media (max-width: 838px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 550px) {
    font-size: 42px;
  }
`;

const HeroSubtitle = styled.h3`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #ffffff;
  margin-bottom: 151px;
  margin-top: 32px;

  @media (max-width: 838px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 550px) {
    font-size: 22px;
  }
`;

const WebinarsHero = () => {
  return (
    <HeroContainer>
      <HeroTitle>
        <strong>All our webinars</strong>, just one click away
      </HeroTitle>
      <HeroSubtitle>
        Don’t miss out on these exclusive tech and talent webinars presented by Nexton experts and our partners
      </HeroSubtitle>
    </HeroContainer>
  );
};

export default WebinarsHero;
