import React from 'react';
import { Box } from 'rebass';
import { graphql, useStaticQuery } from 'gatsby';
import Layout from '../../components/layout/Layout';
import MetaData from '../../components/MetaData';
import WebinarsHub from '../../components/resources/WebinarsHub';
import WebinarsHero from '../../components/resources/WebinarsHero';

const Webinars = () => {
  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/resources/webinarShareImage.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
        }
        name
      }
    }
  `);

  return (
    <>
      <MetaData
        title='Nexton Webinars'
        description='Unlock the latest insights in tech and talent with Nexton`s live and on-demand webinars.'
        image={`https://www.nextonlabs.com${file.childImageSharp.gatsbyImageData.images.fallback.src}`}
      />
      <Layout selected='resources'>
        <Box className='full-width'>
          <WebinarsHero />
        </Box>
        <WebinarsHub />
      </Layout>
    </>
  );
};

export default Webinars;
